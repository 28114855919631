
      function getAbsoluteUrl(relativeUrl) {
        const publicPath = __webpack_public_path__;
        let url = '';
        if (!publicPath || publicPath.indexOf('://') < 0) {
          url += window.location.protocol + '//' + window.location.host;
        }
        if (publicPath) {
          url += publicPath;
        } else {
          url += '/';
        }
        return url + relativeUrl;
      }
import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).env = __SNOWPACK_ENV__;

import React from "../_snowpack/pkg/react.js";
import ReactDOM from "../_snowpack/pkg/react-dom.js";
import "./global.css";
ReactDOM.render(/* @__PURE__ */ React.createElement(React.StrictMode, null, /* @__PURE__ */ React.createElement("div", null, "Hello world")), document.getElementById("root"));
if (undefined /* [snowpack] ({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).hot */ ) {
  undefined /* [snowpack] ({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).hot */ .accept();
}
